<template>
  <div class="top-section">
    <router-link to="/search/form">
      <icon icon="DropdownLeft" style="padding: 1rem" button />
    </router-link>
    <div
      class="top-section-info"
      style="display: grid; grid-template-columns: repeat(3, auto); gap: 0.4rem"
    >
      <p style="margin-left: 0.5rem">
        {{ display.price }}
      </p>
      <p style="color: var(--color-grey)">·</p>
      <p style="color: var(--color-grey)">
        {{ display.info }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { date } from '../../../other/utils/helpers/date'

export default {
  name: 'TopInfo',
  computed: {
    ...mapGetters('SearchState', [
      'travelerCount',
      'searchBudget',
      'passengerText',
    ]),
    ...mapState('SearchState', ['searchParams']),
    display() {
      const params = this.searchParams
      const departureDate = date.formatUTC('-d -ms, -ds', params.departureDate)
      const returnDate = date.formatUTC('-d -ms, -ds', params.returnDate)

      return {
        info: `${departureDate} - ${returnDate}, ${this.passengerText.total}`,
        price: this.$formatPrice(this.searchBudget),
      }
    },
  },
}
</script>

<style scoped lang="scss">
.top-section {
  display: flex;
  border-radius: var(--border-radius-m);
  font-family: DDin;
  box-shadow: var(--shadow-app);
  margin-bottom: 2rem;
  align-items: center;
  background: white;
}
.top-section-info {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.4rem;
}
</style>
