<template>
  <div class="swiper-slide app-card shortlist-card">
    <div v-if="!isLoading" style="display: grid; justify-items: center">
      <IconLogo height="90px" width="100%" />
      <p
        style="
          margin-top: 2rem;
          font-family: GTSuper;
          font-size: 34px;
          text-align: center;
          line-height: 1.25;
        "
      >
        Not what you’re looking for?
      </p>
      <p style="margin-top: 1.5rem">Check out more options!</p>
    </div>
    <AppLoader v-else size="52" />
  </div>
</template>

<script>
import AppLoader from '../../AppLoader.vue'
import IconLogo from '../../icons/common/IconLogo.vue'
export default {
  name: 'ShortlistCard',
  props: { selection: Object, searchAgainCard: Boolean, isLoading: Boolean },
  components: { IconLogo, AppLoader },
}
</script>

<style scoped lang="scss">
.shortlist-card {
  min-height: 500px;
  @include s {
    min-height: 550px;
  }
  padding: 2rem;
  width: 400px;
  max-width: 85vw;
  transition: transform 0.3s ease;
  cursor: pointer;
  overflow: hidden;
  border-radius: 9px;
  display: grid;
  align-content: center;
  justify-items: center;
  background: var(--color-icon-background);
}
</style>
