<template>
  <div class="quick-shortlist">
    <AppBackHeader class="quick-shortlist-header" @back="$emit('back')">
      FLIGHT DETAILS
    </AppBackHeader>
    <div class="quick-shortlist-content">
      <DetailsFlight
        :flight-direction="getActive.flight.departureFlight"
        :price="getActive.flightPrice / FLIGHT.ONE_WAY_PRICE_DIVIDER"
        label="DEPARTURE FLIGHT"
      />
      <DetailsFlight
        :flight-direction="getActive.flight.returnFlight"
        :price="getActive.flightPrice / FLIGHT.ONE_WAY_PRICE_DIVIDER"
        label="RETURN FLIGHT"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { FLIGHT } from '../../../constants/FLIGHT'
import DetailsFlight from '../flights/DetailsFlight.vue'
import AppBackHeader from '../../AppBackHeader.vue'

export default {
  name: 'ShortlistQuickDetails',
  components: { DetailsFlight, AppBackHeader },
  data() {
    return { FLIGHT }
  },
  computed: {
    ...mapGetters('SearchState', ['getActive']),
  },
}
</script>

<style scoped lang="scss">
.quick-shortlist {
  width: 100%;
  margin-top: -1rem;
  border-radius: inherit;
}
.quick-shortlist-header {
  padding: 1rem;
  font-family: LuciferSans;
  font-size: 13px;
  text-align: center;
  margin-left: -1rem;
  margin-right: -1rem;
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
  background: white;
  box-shadow: 0 2px 2px -2px #0005;
}
.quick-shortlist-content {
  display: grid;
  margin-top: 2rem;
  gap: 2rem;
  & > div {
    display: grid;
    gap: 0.75rem;
  }
  cursor: default;
}
</style>
