<template>
  <div class="quick-shortlist">
    <AppBackHeader class="quick-shortlist-header" @back="$emit('back')">
      {{ cityName.toUpperCase() }} - TRIP DETAILS
    </AppBackHeader>
    <div class="quick-shortlist-content">
      <SummaryFlight
        :flight-direction="getActive.flight.departureFlight"
        show-info
        show-card
        info-label="Flight Details"
        label="DEPARTURE FLIGHT"
        @click="$emit('flight-details')"
      />
      <SummaryHotel
        :hotel="getActive.hotel"
        :room="getActive.room"
        :info-price="getActive.hotelPrice"
        show-card
        show-alt
      />
      <SummaryFlight
        :flight-direction="getActive.flight.returnFlight"
        show-info
        show-card
        info-label="Flight Details"
        label="RETURN FLIGHT"
        @click="$emit('flight-details')"
      />
    </div>
    <XButton
      type="large"
      style="width: 100%; margin-top: 2rem"
      @click="$emit('confirm')"
    >
      START BOOKING
    </XButton>
  </div>
</template>

<script>
import SummaryFlight from '../summary/SummaryFlight.vue'
import SummaryHotel from '../summary/SummaryHotel.vue'
import { mapGetters } from 'vuex'
import { FLIGHT } from '../../../constants/FLIGHT'
import XButton from '../../../components/common/global/XButton.vue'
import AppBackHeader from '../../AppBackHeader.vue'

export default {
  name: 'ShortlistQuickDetails',
  components: { SummaryFlight, SummaryHotel, XButton, AppBackHeader },
  data() {
    return { FLIGHT }
  },
  computed: {
    ...mapGetters('SearchState', ['getActive']),
    cityName() {
      return this.getActive.trip.arrivalLocation.display.city
    },
    oneWayFlightPrice() {
      return this.getActive.flightPrice / FLIGHT.ONE_WAY_PRICE_DIVIDER
    },
  },
}
</script>

<style scoped lang="scss">
.quick-shortlist {
  width: 100%;
  margin-top: -1rem;
  border-radius: inherit;
}
.quick-shortlist-header {
  padding: 1rem;
  font-family: LuciferSans;
  font-size: 13px;
  text-align: center;
  margin-left: -1rem;
  margin-right: -1rem;
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
  background: white;
  box-shadow: 0 2px 2px -2px #0005;
}
.quick-shortlist-content {
  display: grid;
  margin-top: 2rem;
  gap: 2rem;
  & > div {
    display: grid;
    gap: 0.75rem;
  }
  // pointer-events: none;
  cursor: default;
}
</style>
