<template>
  <div class="swiper-slide app-card shortlist-card">
    <img
      v-if="!imageLoadFailed && display.imageUrl"
      :src="display.imageUrl"
      class="shortlist-card-image"
      alt="destination"
      @error="imageLoadFailed = true"
    />
    <div
      v-if="!imageLoadFailed && display.imageUrl"
      class="shortlist-card-image-gradient"
    ></div>
    <div v-else class="shortlist-card-image-placeholder">
      <IconPlaceholderCityDark width="60%" height="100%" style="color: white" />
    </div>
    <div class="shortlist-card-header">
      <p style="font-family: GTSuper; font-size: 34px">
        {{ display.city }}
      </p>
      <p v-if="display.country" style="font-family: SpaceMono; font-size: 16px">
        {{ display.country }}
      </p>
    </div>
    <div class="shortlist-card-footer">
      <div
        style="
          display: flex;
          align-items: center;
          color: var(--color-secondary);
          line-height: 1;
        "
      >
        <p
          style="
            font-family: LuciferSans;
            letter-spacing: 0.04em;
            margin-top: 0.5rem;
          "
        >
          Starting from
        </p>
        <p style="font-family: SpaceMono; font-size: 32px; margin-left: 0.5rem">
          {{ $formatPrice(selection.totalPrice) }}
        </p>
      </div>
      <!-- <p>· Fees and baggage not included</p> -->
      <p class="shortlist-card-footer-button">Quick details</p>
    </div>
  </div>
</template>

<script>
import IconPlaceholderCityDark from '../../icons/placeholders/IconPlaceholderCityDark.vue'
export default {
  name: 'ShortlistCard',
  props: { selection: Object },
  data() {
    return { imageLoadFailed: false }
  },
  computed: {
    display() {
      const location = this.selection.trip.arrivalLocation
      return {
        city: location.display.city,
        country: location.display.regionCountry,
        imageUrl: location.imageUrl,
      }
    },
  },
  components: { IconPlaceholderCityDark },
}
</script>

<style scoped lang="scss">
.shortlist-card {
  --button-border-height: 1px;
  min-height: 500px;
  @include s {
    min-height: 550px;
  }
  width: 400px;
  max-width: 85vw;
  transition: transform 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  color: white;
  border-radius: 9px;
  will-change: transform;
  & > * {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    will-change: opacity;
  }
}
@media (hover: hover) {
  .shortlist-card[data-selected]:hover {
    --button-border-height: 2px;
  }
}
.shortlist-card:not([data-selected]) {
  transform: scale(0.98);
  & > * {
    opacity: 0.66;
  }
  & .shortlist-card-cta-button {
    pointer-events: none;
  }
}
.shortlist-card-image,
.shortlist-card-image-placeholder,
.shortlist-card-image-gradient {
  position: absolute;
  width: 100%;
  height: 100%;
}

.shortlist-card-image-placeholder {
  background: var(--color-icon-background-dark);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 4rem;
}
.shortlist-card-image-gradient {
  background: linear-gradient(#0005, #0000 150px);
}
.shortlist-card-header {
  position: relative;
  padding: 1.5rem 1rem;
  line-height: 1.2;
  text-shadow: 1px 2px 3px #0005;
}
.shortlist-card-footer {
  position: relative;
  margin-top: auto;
  min-height: 120px;
  padding: 1.5rem 1rem;
  background: #0008;
  backdrop-filter: blur(1px);
  display: grid;
  justify-items: flex-end;
  align-content: center;
  font-family: DDin;
  font-size: 13px;
  &-button {
    font-size: 20px;
    margin-top: 0.5rem;
    transition: box-shadow 0.2s ease;
    box-shadow: 0 var(--button-border-height) var(--color-secondary);
  }
}
</style>
