<template>
  <div>
    <div class="shortlist-header-bg"></div>
    <div class="shortlist-header container-xs">
      <transition name="float" appear>
        <ShortlistHeader />
      </transition>
    </div>
    <div class="container-xs" style="padding-top: 0.75rem">
      <transition name="slide" appear>
        <div style="font-sie: 13px; display: flex">
          <p style="color: black; font-family: LuciferSans">YOUR SHORTLIST</p>
          <p style="color: #535353; font-family: DDin; margin-left: auto">
            {{ tripCount }} Results
          </p>
        </div>
      </transition>
    </div>
    <Swiper
      :active-index="activeIndex"
      :update-key="tripCount"
      @select="onCardChange"
      @confirm="onCardConfirm"
    >
      <ShortlistCard
        v-for="trip in trips"
        :key="trip.id"
        :selection="getSelection(trip.id)"
        :data-selected="!metaCardSelected && trip.id === tripId"
      />
      <ShortlistCardSearchAgain
        search-again-card
        :data-selected="metaCardSelected"
        :is-loading="isRunning"
      />
    </Swiper>
    <InfoModal ref="tripDetails" hide-close-button background="#f9f9f9">
      <ShortlistQuickDetails
        @confirm="onButtonClick"
        @flight-details="openFlightDetails"
        @back="$refs.tripDetails.hide()"
      />
    </InfoModal>
    <InfoModal
      ref="flightDetails"
      hide-close-button
      background="#f9f9f9"
      @hide="closeFlightDetails"
    >
      <ShortlistQuickFlightDetails @back="closeFlightDetails(true)" />
    </InfoModal>
    <div class="container-xs">
      <XButton
        type="large"
        style="
          width: 100%;
          max-width: 85vw;
          margin-left: auto;
          margin-right: auto;
        "
        :disabled="metaCardSelected && !tripsDepleted"
        @click="onButtonClick"
      >
        {{
          metaCardSelected && tripsDepleted ? 'SEARCH AGAIN' : 'START BOOKING'
        }}
      </XButton>
    </div>
  </div>
</template>

<script>
import ShortlistHeader from '@/components-rf/search/shortlist/ShortlistHeader.vue'
import Swiper from '@/components/utils/Swiper.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import InfoModal from '../../components-rf/modals/InfoModal.vue'
import ShortlistCard from '../../components-rf/search/shortlist/ShortlistCard.vue'
import ShortlistQuickDetails from '../../components-rf/search/shortlist/ShortlistQuickDetails.vue'
import XButton from '../../components/common/global/XButton.vue'
import { appAnalytics } from '../../services/analytics/analytics'
import ShortlistCardSearchAgain from '../../components-rf/search/shortlist/ShortlistCardSearchAgain.vue'
import ShortlistQuickFlightDetails from '../../components-rf/search/shortlist/ShortlistQuickFlightDetails.vue'

export default {
  name: 'SearchCity',
  components: {
    ShortlistHeader,
    Swiper,
    ShortlistCard,
    InfoModal,
    ShortlistQuickDetails,
    XButton,
    ShortlistCardSearchAgain,
    ShortlistQuickFlightDetails,
  },
  mounted() {
    appAnalytics.page({
      title: 'search_screen_results_view',
      search: this.searchType,
    })
  },
  beforeDestroy() {
    appAnalytics.track('search_results_user_swipe_count', {
      count: this.swipeCount,
    })
  },
  data() {
    return {
      activeIndex: 0,
      tripSelected: true,
      tripsDepleted: false,
      swipeCount: 0,
    }
  },
  watch: {
    activeIndex(index) {
      this.swipeCount += 1
      this.selectTrip(index)
      this.searchMore()
    },
    tripCount() {
      if (!this.tripSelected) this.selectTrip(this.activeIndex)
    },
    isRunning(flag) {
      if (!flag) this.searchMore()
    },
  },
  methods: {
    ...mapActions('SearchState', ['select']),
    ...mapActions('SearchStream', ['startSearch', 'stopSearch']),
    onCardChange(index) {
      this.activeIndex = index
    },
    onCardConfirm() {
      if (this.tripSelected) this.showTripDetails()
      else if (this.tripsDepleted) this.searchAgain()
    },
    selectTrip(index) {
      const trip = this.trips[index]
      this.tripSelected = !!trip
      if (trip)
        this.select({
          tripId: trip.id,
          confirm: true,
          preview: true,
          ignoreHotelDetails: true,
        })
    },
    showTripDetails() {
      appAnalytics.track('search_result_show_info')
      this.$refs.tripDetails.show()
    },
    onButtonClick() {
      if (this.metaCardSelected) this.searchAgain()
      else this.confirmTrip()
    },
    confirmTrip() {
      appAnalytics.track('search_result_start_booking')
      this.$router.push('/search/summary')
    },
    searchAgain() {
      this.$router.push({
        path: '/search/q',
        query: { ...this.getParams.search, rerun: true },
      })
    },
    searchMore() {
      const { isRunning, tripsDepleted, tripCount, distanceFromLast } = this
      if (tripsDepleted || isRunning || distanceFromLast > 1) return
      this.startSearch({
        form: { ...this.getParams.search, rerun: true },
        append: true,
        onEnd: () => {
          const newTripCount = this.tripCount
          this.tripsDepleted = tripCount === newTripCount
        },
      })
    },
    getSelection(tripId) {
      return this.getSelected({ tripId, confirmed: true })
    },
    openFlightDetails() {
      this.$refs.tripDetails.hide()
      this.$refs.flightDetails.show()
    },
    closeFlightDetails(force = false) {
      if (force) this.$refs.flightDetails.hide()
      this.$refs.tripDetails.show()
    },
  },
  computed: {
    ...mapGetters('SearchForm', ['getParams']),
    ...mapGetters('SearchState', ['trips', 'getSelected', 'searchType']),
    ...mapState('SearchState', ['tripId']),
    ...mapState('SearchStream', ['isRunning']),
    metaCardSelected() {
      return this.activeIndex === this.tripCount
    },
    tripCount() {
      return this.trips.length
    },
    distanceFromLast() {
      return this.tripCount - this.activeIndex - 1
    },
  },
}
</script>

<style scoped lang="scss">
.shortlist-header {
  position: relative;
  z-index: 20;
  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 90px;
    background: var(--color-secondary);
    z-index: 20;
  }
}
</style>
